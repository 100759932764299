/* Plugins
---------------------------------------------------------------------------- */

/* Genesis eNews Extended
--------------------------------------------- */

.after-entry .enews {
	text-align: center;
	padding: 10px;
}

.sidebar .enews {
	background-color: #f5f5f5;
	padding: 30px;
}

.enews-widget input {
	font-size: 16px;
	margin-bottom: 10px;
}

.after-entry .enews-widget input {
	text-align: center;
}

.enews-widget input[type="submit"] {
	margin: 0;
	width: 100%;
}

.enews form + p {
	margin-top: 20px;
}

/* Genesis Simple FAQ
--------------------------------------------- */

.gs-faq__question {
	background: transparent;
	border-bottom: 1px solid #eee;
	color: #333;
	padding-left: 0;
	padding-right: 0;
}

.gs-faq__question:focus,
.gs-faq__question:hover {
	background: transparent;
	color: var(--link-hover-color);
}

.gs-faq__question::after {
	content: "\f132";
	font-family: dashicons; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
	float: right;
}

.gs-faq__question.gs-faq--expanded::after {
	content: "\f460";
}

/* WP Forms
--------------------------------------------- */

.entry-content .wpforms-container {
	margin-bottom: 40px;
}

.entry-content .wpforms-form .wpforms-field {
	clear: both;
	margin: 20px 0;
	overflow: hidden;
}

.site-container .wpforms-container .wpforms-form .wpforms-field input {
	border-radius: 0;
	height: auto;
	padding: 15px;
}

.site-container .entry-content .wpforms-form .wpforms-field-label {
	font-weight: 600;
}

.site-container .entry-content .wpforms-form .wpforms-field-sublabel {
	font-size: 14px;
	font-weight: 300;
}

.entry-content .wpforms-form .wpforms-field-hp {
	display: none !important;
	left: -9000px !important;
	position: absolute !important;
}

.site-container .entry-content .wpforms-form textarea {
	padding: 15px;
}

.site-container .entry-content .wpforms-form textarea.wpforms-field-small {
	height: 120px;
}

.site-container .entry-content .wpforms-form textarea.wpforms-field-medium {
	height: 200px;
}

.site-container .entry-content .wpforms-form textarea.wpforms-field-large {
	height: 300px;
}

/* Booking */

.booking-container {
	height: calc(100vh - 156px - 40px);
}

.stickHeader .booking-container {
	height: calc(100vh - 55px - 40px);
}

/* .jquery-background-video-wrapper {
	position: relative;
	
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.5);
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAJElEQVQYV2NkwAT/GdHE/jMwMDAiC4IFQIpggnABmCCKAEgQAABOBQUaykC8AAAAAElFTkSuQmCC) repeat;
		z-index: 1;
	}
} */

.footer-form {
	& .wpforms-confirmation-container-full, div[submit-success]>.wpforms-confirmation-container-full:not(.wpforms-redirection-message) {
		border: 1px solid var(--primary);
	}

	& .wpforms-confirmation-container-full {
		background: transparent !important;
	}
}
