/* Media Queries
---------------------------------------------------------------------------- */

@media only screen and (min-width: 480px) {

	table {
		table-layout: auto;
		word-break: normal;
	}

}

@media only screen and (min-width: 960px) {

	.site-container {
		--font-size: var(--font-size-960);
		
		font-size: var(--font-size);
	}
	
	/* Site Header
	--------------------------------------------- */

	.site-header {
		overflow: hidden;
	
		&:hover,
		&:focus {
			overflow: visible;
		}
		
		.admin-bar & {
			top: 32px;
		}
	}

	/* Genesis Menu
	--------------------------------------------- */

	.genesis-nav-menu .menu-item {
		display: inline-block;
	}

	.genesis-nav-menu .menu-item:focus,
	.genesis-nav-menu .menu-item:hover {
		position: relative;
	}

	.genesis-nav-menu > .menu-bold > a {
		font-weight: 700;
	}

	.genesis-nav-menu > .menu-highlight > a {
		background-color: #333;
		border-radius: 3px;
		color: var(--white);
		font-weight: 600;
		margin-left: 15px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.genesis-nav-menu > .menu-highlight > a:focus,
	.genesis-nav-menu > .menu-highlight > a:hover {
		background-color: var(--link-hover-color);
	}

	.genesis-nav-menu .sub-menu {
		width: 250px;
	}
	
	.genesis-nav-menu .sub-menu a {
		width: 100%;
	}

	.genesis-nav-menu .sub-menu {
		border: 1px solid #eee;
		border-top-width: 0;
		border-radius: 0 0 5px 5px;
		background-color: var(--background-color);
		opacity: 0;
		padding-left: 0;
		position: absolute;
		transition: opacity 0.4s ease-in-out;
		box-shadow: 0 2px -2px inset rgba(0,0,0,0.1);
	}

	.genesis-nav-menu .sub-menu a {
		border-top: 0;
		padding-bottom: 12px;
		padding-top: 12px;
		line-height: 21px;
	}

	/* .genesis-nav-menu .sub-menu .sub-menu {
		margin: -46px 0 0 179px;
	} */

	/* Responsive Menu
	--------------------------------------------- */

	.js .nav-primary {
		display: flex;
		/* padding-top: 15px; */
	}
	
	/* additions... */
	
	.genesis-responsive-menu {
		display: flex;
		justify-content: space-evenly;
		width: 100%;	
	}
	
	.genesis-nav-menu > .menu-item > a {
		text-align: center;
	}
	
	.stickHeader .genesis-responsive-menu .wrap {
		padding: 0;
	}

	/* ...additions */
	
	.menu-toggle,
	.sub-menu-toggle {
		display: none;
		visibility: hidden;
	}

	/* Header Menu
	--------------------------------------------- */

	.nav-primary {
		clear: none;
		float: right;
		/* width: auto; */
	}
	
	/* additions... */
	
	.nav-primary .genesis-nav-menu {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    margin: 0 -20px;
    width: auto;
		height: 100%;
	}
	
	.stickHeader .nav-primary .genesis-nav-menu {
		padding: 0 8px;
		margin: 0;
	}
	
	.nav-primary .genesis-nav-menu .menu-item {
		padding: 0 5px;
	}
	
	.nav-primary .genesis-nav-menu > .menu-item > a {
		display: flex;
		align-items: center;
		height: 100%;
	}
	
	.stickHeader .nav-primary .genesis-nav-menu > .menu-item {
		padding-bottom: 0;
		padding-top: 0;
	}
	
	/* ...additions */

	.nav-primary .genesis-nav-menu a {
		padding-left: 15px;
		padding-right: 15px;
	}

	/* additions... */

	.genesis-responsive-menu .genesis-nav-menu .sub-menu {
		/* left: 50%; */
		/* transform: translateX(-50%); */
		transform: translateX(-5px);
		margin: 0 auto;
		text-align: left;
		padding-top: 0.5em;
		min-width: 250px;
		top: 100%;
	}
	
	.stickHeader 	.genesis-responsive-menu .genesis-nav-menu .sub-menu {
		transform: translateX(-5px) translateY(-3px);
	}
	
	.genesis-responsive-menu .sub-menu .menu-item {
		width: 100%;
	}
	
	.genesis-responsive-menu .sub-menu .menu-item:last-of-type a {
		padding-bottom: 30px;
	}

	.genesis-responsive-menu .sub-menu a {
		float: left;
		width: 100%;
	}
	
	/* ...additions */

	/* Site-Inner
	--------------------------------------------- */

	.site-inner {
		max-width: 1240px;
	}

	/* Content
	--------------------------------------------- */

	.content {
		float: left;
		width: 65%;
	}
	
	@supports (display: grid) {
		.content {
			float: left;
			width: revert;
		}
	}
	
	.content-sidebar-wrap .content {
		float: left;
	}

	.sidebar-content .content {
		float: right;
	}

	.full-width-content .content {
		float: none;
		margin-left: auto;
		margin-right: auto;
	}

	/* Sidebar
	--------------------------------------------- */

	.sidebar {
		float: right;
		width: 30%;
	}

	.sidebar-content .sidebar {
		float: left;
	}
	
	/* Grid */
	
	@supports (position: sticky) {
		.content-sidebar .content-sidebar-wrap {
			float: left;
		}
		.content-sidebar .sidebar {
			position: sticky;
			top: 95px;
		}
	}

	@supports (display: grid) {	
		.content-sidebar-wrap,
		.content-sidebar-wrap > * {
			float: none;
		}	
			
		.content-sidebar {
			& .sidebar {
				width: 100%;
			}
		}
		
		/* .content-sidebar .content-sidebar-wrap, */
		.content-sidebar .site-inner {
			grid-template-columns: 0.685fr 0.315fr;
			grid-column-gap: 5%;
		}
		
		.site-inner {
			grid-template-areas: "breadcrumb breadcrumb" "title title" "meta meta"  "image image" "content aside" "footer aside";
			
			.archive & {
				grid-template-areas: "breadcrumb breadcrumb" "title title" "loop aside";
			}
			
			.blog & {
				grid-template-areas: "breadcrumb breadcrumb" "title title" "categories categories" "loop aside";
			}
		}
	}

	/* Author Box
	--------------------------------------------- */

	.author-box {
		background-color: #f5f5f5;
		padding: 30px;
	}

	/* After Entry
	--------------------------------------------- */

	.after-entry {
		padding: 40px 60px;
	}

	.after-entry .enews {
		padding-left: 30px;
		padding-right: 30px;
	}

	/* Column Classes
	--------------------------------------------- */

	.five-sixths,
	.four-sixths,
	.one-fourth,
	.one-half,
	.one-sixth,
	.one-third,
	.three-fourths,
	.three-sixths,
	.two-fourths,
	.two-sixths,
	.two-thirds {
		float: left;
		margin-left: 2.564102564102564%;
	}

	.one-half,
	.three-sixths,
	.two-fourths {
		width: 48.717948717948715%;
	}

	.one-third,
	.two-sixths {
		width: 31.623931623931625%;
	}

	.four-sixths,
	.two-thirds {
		width: 65.81196581196582%;
	}

	.one-fourth {
		width: 23.076923076923077%;
	}

	.three-fourths {
		width: 74.35897435897436%;
	}

	.one-sixth {
		width: 14.52991452991453%;
	}

	.five-sixths {
		width: 82.90598290598291%;
	}

	.first {
		clear: both;
		margin-left: 0;
	}

	/* Entry Misc.
	--------------------------------------------- */

	.after-entry,
	.archive-description,
	.author-box,
	.comment-respond,
	.entry,
	.entry-comments,
	.entry-pings,
	.single .site-inner {
		margin-bottom: 60px;
	}

	/* Footer Widgets
	--------------------------------------------- */

	.footer-widgets .wrap {
		max-width: 1240px;
	}

	.footer-widget-area {
		float: left;
		margin-bottom: 0;
		/* width: calc(100% / 3); */
	}
	
	.site-footer .wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		
		&::before {
			display: none;
		}
	}
	
	.site-footer .nav-secondary {
		margin-top: 0;
		margin-right: -10px;
		font-family: var(--heading-font-family);
	}
}

@media only screen and (min-width: 960px) and (max-width: 1043px) {
	.stickHeader .nav-primary .genesis-nav-menu > .menu-item {
		padding-left: 3px;
		padding-right: 3px;
	}
	
	.stickHeader .nav-primary .genesis-nav-menu > .menu-item > a {
		padding-left: 6px;
		padding-right: 6px;
		font-size: 14px;
	}
}

@media only screen and (min-width: 1044px) and (max-width: 1151px) {
	.stickHeader .nav-primary .genesis-nav-menu > .menu-item > a {
		padding-left: 8px;
		padding-right: 8px;
	}
}

/* Additions... */

@media only screen and (max-width: 1200px) {
	
	.image-text-block-container .image-text-grid-content-wrap {
		padding: 80px;
	}
	
	.image-text-block-container:nth-child(even) .image-text-grid-content-wrap {
		padding-left: 20px;
	}

	.image-text-block-container:nth-child(odd) .image-text-grid-content-wrap {
		padding-right: 20px;
	}
	
	.image-text-block-container.image-text-block-container .image-text-content-container {
		padding: 40px;
	}
	
	.image-text-block-verbose .image-text-block-image {
		width: calc(45% - 40px);
	}
	
}

@media only screen and (max-width: 1023px) {
	
	.image-text-block-container.image-text-block-container .image-text-grid-content-wrap {
		padding: 40px;
	}
}

@media only screen and (max-width: 959px) {

	/*.stickHeader .genesis-responsive-menu,
	.stickHeader .nav-primary {
		position: absolute;
		top: 60px;
	}*/
  
  .genesis-nav-menu .sub-menu-toggle {
    position: static;
    line-height: 1;
    height: auto;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
    
  .genesis-nav-menu .sub-menu {
    grid-column: span 2;
  }
	
	.genesis-nav-menu .sub-menu a {
		background-color: transparent;
	}
	
	.site-header {
		height: auto;
	}
	
	.stickHeader .genesis-responsive-menu {
		background-color: var(--background-color);
    backdrop-filter: blur(5px);
		display: none;
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		/* z-index: 999; */
	}
  
  .stickHeader .genesis-responsive-menu::before {
    content: "";
    display: block;
    position: absolute;
    top: -100%;
    bottom: 0;
    left: 0;
    right: 0;
    /* z-index: 999; */
    box-shadow: 0 10px 20px var(--dark-semi-transparent);
  }

	.menu-toggle {
		padding: 0 0 10px;
	}
	
	.stickHeader .menu-toggle {
		padding: 0;
	}
	
	.genesis-responsive-menu ul#menu-main-menu {
		margin: 0 auto;
		padding: 20px 40px;
    max-width: 400px;
    box-sizing: content-box;
	}
	
	.genesis-responsive-menu ul#menu-main-menu li {
    box-sizing: border-box;
		padding: 0;
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  	min-height: 44px;
    position: static;
	}
  
  .genesis-responsive-menu ul#menu-main-menu .menu-item-has-children a span {
    margin-right: 1em;
  }
  
  .genesis-responsive-menu ul#menu-main-menu a {
    padding-top: 0;
    padding-bottom: 0;
    flex-grow: 1;
  }
	
	.booking-button-container {
		top: 167px;
	}
	
	#slider-1 .slides li {
		height: 600px;
	}
	
	.footer-widgets .wrap {
		flex-direction: column;
	}
	
	.footer-widgets-1 {
		width: 100%;
	}

	.footer-widgets-2	{
		width: 100%;
		margin-top: 20px;
	}
	
	.nav-primary {	
		margin-left: -20px;
		margin-right: -20px;
		padding-left: 10px;
		padding-right: 10px;
		width: 100vw;
		background-color: var(--secondary);
	}
	
	.stickHeader .nav-primary {
		margin: 0;
	}
}

@media only screen and (max-width: 820px) {
	
	h1 {
		font-size: 36px;
	}
	
}

@media only screen and (max-width: 768px) {
	
	.site-header.stickHeader {
		height: auto;
	}
	
	.wp-custom-logo .title-area {
		padding-top: 5px !important;
		margin-bottom: -5px;
	}
	
	.wp-custom-logo .title-area img {
		object-fit: cover;
		object-position: top;
	}
	
	.space-block-20 {
		padding-bottom: 10px
	}

	.space-block-30 {
		padding-bottom: 15px
	}

	.space-block-40 {
		padding-bottom: 20px
	}

	.space-block-50 {
		padding-bottom: 25px
	}

	.space-block-60 {
		padding-bottom: 30px
	}

	.space-block-80 {
		padding-bottom: 40px
	}

	.space-block-100 {
		padding-bottom: 50px
	}
	
	.image-text-grid-image {
		width: 100%;
	}
	
	.image-text-block-container .image-text-grid-content-wrap {
		padding: 40px 20px;
	}
	
	.image-text-block-container:nth-child(even) {
		& .image-text-grid-content-wrap {
			align-items: flex-start;
		}
		
		& .image-text-grid-image {
			order: 0;
		}

		& .image-text-content-container {
			text-align: left; 
			align-items: flex-start;
		}
		
		& .image-text-content-container .button-wrap {
			float: left; 
		}
	}
	
	.image-text-grid-content-container  {
		width: 100%;
	}
	
	.image-text-grid-content-wrap {
		max-width: 100%;
		width: 100%;
		float: left;
		padding: 40px;
	}
	
	.image-text-item-container {
		display: block;
	}
	
	.wide-fixed-section-wrap.image-text-block-wrapper {
		padding: 0 2px;
	}
	
	.image-text-block-container:nth-child(even) .image-text-grid-content-wrap {
		float: left;
		text-align: left;
	}
	
	.image-text-block-image,
	.image-text-block-verbose .image-text-block-image {
		width: 100%;
	}
	
	.image-text-content-container,
	.image-text-block-verbose .image-text-content-container {
		width: 100%;
	}
	
	.image-text-block-container .image-text-content-container {
		padding: 40px;
	}
	
	.image-text-block-container:nth-child(even) {
		& .image-text-block-image {
			order: 0;
		}

		& .button-container {
			float: left;
		}
	}
	
	.image-text-block-container:nth-child(even) {
		& .image-text-block-button-container {
			justify-content: flex-start;
			
			& .button-wrap.button-no-2 {
				margin-left: 0;
			}

			& .button-wrap.button-no-1 {
				margin-left: 0;
				margin-right: 20px;
			}
		}
	}

}

@media only screen and (max-width: 680px) {
	
	.site-header > .wrap {
		flex-direction: column;
	}
	
	.title-area,
	.wp-custom-logo .title-area {
		/* max-width: 50%; */
		padding: 10px 0 !important;
	}

	.site-header.stickHeader + * {
		margin-top: 48px;
	}

	.header-widget-area {
		top: 10px;
		max-width: 50%;
	}
	
	.booking-button-container {
		top: 188px;
	}
	
	.booking-button-wrapper {
		align-items: center;
	}
	
	.booking-button-block {
		display: flex;
	}
	
	.booking-button-block .button-wrap {
		padding: 5px;
	}
	
	#slider-1 .slides li {
		height: 500px;
	}

}

@media only screen and (max-width: 620px) {
	
	.genesis-responsive-menu ul#menu-main-menu {
		padding: 20px 0;
	}
	
	.image-text-block-image {
		padding-right: 20px;
	}
	
	.form-block-content {
		width: 100%;
		order: 1;
	}

	.form-block-right-content {
		width: 100%;
		padding-bottom: 20px;
	}

	.form-block-right-wrap {
		border-left: none;
		padding-left: 0;
		margin-left: 0;
	}
	
}

@media only screen and (max-width: 600px) {
	
	.booking-button-block {
		flex-direction: column;
	}
}

@media only screen and (max-width: 580px) {

	.image-text-block-image {
		width: 100%;
		width: 100%;
		padding: 0;
		text-align: center;
	}

	.image-text-content-container {
		width: 100%;
	}
	
	.social-content-block {
		flex-direction: column;
	}
	
	.button-block-container {
		padding: 40px 0;
	}

	.social-heading-block-container h2,
	.social-heading-icons-container {
		width: 100%;
		margin-bottom: 10px;
	}

	.footer-form .gform_body {
		float: left;
		width: 100%;
	}

	.footer-form .gform_footer {
		float: left;
		margin-top: 20px;
	}

	.footer-social-block-container:before {
		top: -23px;
	}
	
	.footer-social-block-container {
		padding: 20px 0 40px;
	}

	.footer-widgets #menu-footer-menu .menu-item {
		width: 100%;
		margin-bottom: 10px;
	}

}

@media only screen and (max-width: 480px) {
	
	.site-inner {
		padding: 20px;
	}
	
	.site-header.stickHeader + * {
		margin-top: 92px;
	}
	
	.site-header.stickHeader .title-area,
	.site-header.stickHeader .header-widget-area {
		display: none;
	}
	
	.site-header.stickHeader .menu-toggle {
		padding: 0;
	}
	
	.stickHeader .genesis-responsive-menu {
		position: absolute;
		/* top: 30px; */
	}
	
	h1 {
		font-size: 30px;
	}
	
	.gform_body li.gfield label {
		width: 100%;
	}

	.gform_body li.gfield .ginput_container {
		width: 100%;
	}
	
	.gform_footer {
		left: 0;
	}
	
	.social-block-content-wrapper {
		justify-content: center;
	}
	
	/*.image-text-block-button-container {
		flex-direction: column;
	}*/
	
	.image-text-block-button-container .button-wrap.button-no-1 {
		margin-right: 0;
		
		.image-text-block-container:nth-child(even) & {
			margin-left: 0;
		}
	}
	
}

@media only screen and (max-width: 360px) {
	
	.large-button {
		max-width: 180px;
	}
	
	.large-button-wrap {
		display: block;
		padding: 0;
	}

	.twothirds-width-input {
		width: 100%;
	}

	.third-width-input {
		width: 100%;
	}

	.half-width-input {
		width: 100%;
	}

	.footer-form li.gfield.third-width-input {
		padding:0;
		margin-top: 20px;
	}
	
}
