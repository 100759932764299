/*site content container*/

.full-width-content .content,
.landing-page .content {
  width: 100%;
}

.ulitily-bar {
  float: left;
  width: 100%;
  padding: 0 20px;
}

.ulitily-bar-wrap {
  max-width: 1240px;
  width: 100%;
  text-align: right;
  margin: 0 auto;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  padding: 0;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 10px;
}

.cart-contents {
  color: var(--dark);
  font-family: var(--body-font-family);
  font-size: 14px;
  letter-spacing: 0.05em;
  font-weight: 400;
  z-index: 9999;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.cart-contents > img {
  width: 15px;
  margin-right: 5px;
}

.cart-contents {
  margin-right: 10px;
}

.social-header-social-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 20px;
}

.checkout-link {
  color: var(--dark);
  font-family: var(--body-font-family);
  font-size: 17px;
  letter-spacing: 0.05em;
  font-weight: 400;
  text-decoration: none;
  line-height: normal;
  text-decoration: none;
}

.checkout-link:before {
  content: "|";
  color: var(--dark);
  margin-right: 10px;
}

@supports (display: grid) {
  .content-sidebar-wrap > * {
    width: 100%;
    align-content: stretch;
  }

  .content-sidebar .content-sidebar-wrap {
    grid-template-areas: "main" "aside";
  }

  .content-sidebar .content-sidebar-wrap,
  .single .site-inner,
  .archive .site-inner,
  .blog .site-inner,
  .content-sidebar .site-inner {
    display: grid;
  }
  .content-sidebar .sidebar {
    align-self: start;
  }

  .site-inner {
    grid-template-areas: "breadcrumb" "title" "meta" "image" "content" "footer" "aside";
  }

  .breadcrumb {
    grid-area: breadcrumb;
  }

  .content {
    grid-area: main;
  }

  .sidebar {
    grid-area: aside;
  }

  .blog_categories_showcase {
    grid-area: categories;

    & > :first-child {
      margin-top: 0;
    }
  }

  .archive-description {
    grid-area: title;
  }

  .loop {
    grid-area: loop;
  }
  
  .content {
    grid-area: content;
  }

  .content-sidebar {
    & .content-sidebar-wrap,
    &.archive .content,
    &.single .content,
    &.blog .content {
      display: contents;
    }
  }

  .archive .site-inner {
    grid-template-areas: "breadcrumb" "title" "loop" "aside";
  }

  .blog .site-inner {
    grid-template-areas: "breadcrumb" "title" "categories" "loop" "aside";
  }

  .single {
    & .loop,
    & .entry,
    & .entry-header {
      display: contents;
    }

    & .entry-meta {
      grid-area: meta;
    }

    & .entry-title {
      grid-area: title;
    }

    & .entry-image {
      grid-area: image;
      margin-bottom: var(--paragraph-margin-v);
      border-bottom: thin solid var(--primary-semi-transparent);
      padding-bottom: var(--paragraph-margin-v);
    }

    & .entry-content {
      grid-area: content;
    }

    & .entry-footer {
      grid-area: footer;
    }
  }
}
