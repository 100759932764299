/* Site Navigation
---------------------------------------------------------------------------- */

.genesis-nav-menu {	
	clear: both;
	line-height: 1;
	width: 100%;
}

.nav-primary .wrap {
	/* additions */
	width: 100%;
}

.genesis-nav-menu .menu-item {
	display: block;
	float: none;
	position: relative;
}

.genesis-nav-menu a {
	color: var(--link-color);
	display: block;
	font-weight: 400;
	/* outline-offset: -1px; */
	text-decoration: none;
	
	/* additions */
	position: relative;
	padding: 1em;
	
	/* border: thin solid transparent; */
	/* padding: 0.5em 1em; */
	/* border-radius: var(--border-radius); */
	
	text-transform: var(--uppercase-text-transform);
	letter-spacing: var(--uppercase-letter-spacing);
	font-size: var(--uppercase-font-size);
}

.genesis-nav-menu a:focus,
.genesis-nav-menu a:hover,
.genesis-nav-menu .current-menu-item > a,
.genesis-nav-menu .sub-menu .current-menu-item > a:focus,
.genesis-nav-menu .sub-menu .current-menu-item > a:hover {
	color: var(--link-hover-color);
	text-decoration: none;
}

/* .genesis-nav-menu .sub-menu,
.genesis-nav-menu .sub-menu a {
	width: 100%;
} */

.genesis-nav-menu .sub-menu {
	font-size: 0.875em;
	clear: both;
	display: none;
	left: -9999px;
	margin: 0;
	opacity: 1;
	padding-left: 15px;
	position: static;
	z-index: 99;
}

.genesis-nav-menu .sub-menu a {
	position: relative;
	word-wrap: break-word;
}

.genesis-nav-menu .menu-item:focus,
.genesis-nav-menu .menu-item:hover {
	position: relative;
}

.genesis-nav-menu .menu-item:hover > .sub-menu {
	display: block;
	left: auto;
	opacity: 1;
}

.genesis-nav-menu .current-menu-item > a {
	color: var(---link-color);
	/* border-color: var(--primary); */
}

.genesis-nav-menu .current-menu-item > a > span,
.genesis-nav-menu .current-menu-ancestor > a > span {
	text-decoration: underline;
	text-decoration-color: var(--primary);
}

.genesis-nav-menu .current-menu-item > a:hover {
	color: var(--link-hover-color);
}

/* Responsive Menu
--------------------------------------------- */

.menu .menu-item:focus {
	position: static;
}

.menu .menu-item > a:focus + ul.sub-menu,
.menu .menu-item.sfHover > ul.sub-menu {
	left: auto;
	right: auto;
	opacity: 1;
}

#menu-item-240 .sub-menu {
	min-width: 220px;
	max-width: 240px;
}

.js .nav-primary {
	display: none;
	position: relative;
}

.genesis-responsive-menu .genesis-nav-menu .menu-item:hover > .sub-menu {
	display: none;
}

.menu-toggle,
.sub-menu-toggle {
	background-color: transparent;
	border-width: 0;
	color: var(--link-color);
	display: flex;
	align-items: center;
	margin: 0 auto;
	overflow: hidden;
	text-align: center;
	visibility: visible;
}

.menu-toggle:focus,
.menu-toggle:hover,
.sub-menu-toggle:focus,
.sub-menu-toggle:hover {
	background-color: transparent;
	border-width: 0;
	color: var(--link-hover-color);
}

.menu-toggle {
	float: right;
	line-height: 20px;
	/* margin-bottom: 10px;
	margin-top: 10px; */
	padding: 15px 0;
	position: relative;
	z-index: 1000;
}

.menu-toggle.activated::before {
	content: "\f335";
}

.site-header .dashicons-before::before {
	transition: none;
}

/* additions... */

.dashicons-menu::before {
    width: 30px !important;
    height: 30px !important;
    font-size: 30px !important;
}

.menu-toggle.activated::before {
	font-size: 30px;
	content: "\f335";
	width: 30px;
    height: 30px;
}

/* ...additions */

.site-header .menu-toggle::before {
	float: left;
	margin-right: 5px;
	position: relative;
	text-rendering: auto;
	top: -1px;
}

.sub-menu-toggle {
	float: right;
	padding: 9px 10px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 100;
}

.sub-menu .sub-menu-toggle {
	padding: 12px 10px;
}

.sub-menu-toggle::before {
	display: inline-block;
	text-rendering: auto;
	transform: rotate(0);
	transition: transform 0.25s ease-in-out;
}

.sub-menu-toggle.activated::before {
	transform: rotate(180deg);
}

/* Header Menu
--------------------------------------------- */

.nav-primary {
	clear: left;
	width: 100%;
}

/* Footer Menu
--------------------------------------------- */

.nav-secondary {
	margin-top: 10px;
}

.nav-secondary .genesis-nav-menu {
	line-height: 1.5;
}

.nav-secondary .menu-item {
	display: inline-block;
}

.nav-secondary a {
	margin-left: 10px;
	margin-right: 10px;
	padding: 0;
}
