/* Sidebar
---------------------------------------------------------------------------- */

.sidebar {
	font-size: 16px;
	line-height: 1.5;
	position: sticky;
	top: 0;
}

.sidebar .widget {
	margin-bottom: 40px;
}

.sidebar p {
	margin-bottom: 20px;
}
