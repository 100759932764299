/* Banner Block */

.banner-block-container {
	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	padding: 120px 0 100px;
}

.banner-block-container:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.banner-block-container.overlay-white {
	--background-color: var(--white);
}

.banner-block-container.overlay-coloured {
	--background-color: var(--secondary);
	--foreground-color: var(--secondary-contrast);
}

.banner-block-container:before {
	background-color: var(--background-color);
}

.banner-block-wrap {
	color: var(--foreground-color);
}

.banner-block-wrap h2 {
	position: relative;
}

.align-left .banner-block-wrap {
	max-width: 430px;
    width: 100%;
	float: left;
	text-align: right;
}

.align-right .banner-block-wrap {
	max-width: 500px;
    width: 100%;
	float: right;
	text-align: left;
}

.align-center .banner-block-wrap {
	max-width: 820px;
    width: 100%;
    margin: 0 auto;
	text-align: center;
}

.banner-block-content-container {
	position: relative;
	font-family: var(--body-font-family);
	letter-spacing: 0.05em;
    overflow: hidden;
}

.align-left .banner-block-content-container,
.align-right .banner-block-content-container {
	font-size: 14px;
	font-weight: 400;
}

.align-center .banner-block-content-container {
	font-size: 18px;
	line-height: 1.3em;
	font-style: italic;
	font-weight: 400;
}

.banner-button-container {
	/* float: left; */
	width: 100%;
	margin-top: 40px;
}

.banner-button-container .button-wrap {
	display: flex;
  align-items: center;
}

.align-center .button-wrap {
	justify-content: center;
}

.align-left .button-wrap {
	justify-content: flex-end;
}

.align-right .button-wrap {
	justify-content: flex-start;
}
