/* Entry Meta
--------------------------------------------- */

p.entry-meta {
	font-size: 16px;
	margin-bottom: 0;
}

.entry-header .entry-meta {
	margin-bottom: var(--paragraph-margin-v);
}

.entry-footer {
	margin-top: var(--paragraph-margin-v);
}

.entry-footer .entry-meta {
	border-top: thin solid var(--primary-semi-transparent);
	padding-top: calc(var(--paragraph-margin-v) / 2);
;
}

.entry-categories,
.entry-tags {
	display: block;
}

.entry-comments-link::before {
	content: "\2014";
	margin: 0 6px 0 2px;
}
