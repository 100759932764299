/* Heading Block */

.heading-block-container {
	padding: var(--section-gap) 0;
	text-align: center;
}

.heading-block-wrapper {
	width: 100%;
}

.background-white.border-no {
	border: 0 solid var(--primary-semi-transparent);
	border-width: thin 0;
}

.background-coloured.border-yes .heading-block-wrapper {
	border: 1px solid var(--white);
}

.background-white.border-yes .heading-block-wrapper {
	border: 1px solid var(--secondary);
}

.heading-block-content {
	max-width: 900px;
	width: 100%;
	margin: 0 auto;
	padding: 40px;
}

.heading-block-container.background-coloured {
	--background-color: var(--secondary);
	--foreground-color: var(--secondary-contrast);
}

.heading-block-container.background-white {
	--background-color: var(--white);
	--foreground-color: var(--dark);
}

.heading-block-title {
	font-family: var(--heading-font-family);
    /* font-size: 1.5em; */
    /* letter-spacing: 0.05em; */
    /* text-transform: inherit; */
    /* font-weight: 400; */
    /* max-width: 540px; */
	width: 100%;
	margin: 0 auto 20px;
}

.heading-block-title:only-child {
	margin-bottom: 0;
}

.heading-block-button-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.heading-block-button-container .button-container {
	padding: 10px;
}
