/* Skip Links
---------------------------------------------------------------------------- */

.genesis-skip-link {
	margin: 0;
}

.genesis-skip-link .skip-link-hidden {
	display: none;
	visibility: hidden;
}

.genesis-skip-link li {
	height: 0;
	list-style: none;
	width: 0;
}

/* Display outline on focus */
:focus {
	color: #333;
	outline: #ccc solid 1px;
}
