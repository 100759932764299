/* Screen Reader Text
--------------------------------------------- */

.screen-reader-shortcut,
.screen-reader-text,
.screen-reader-text span {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

.screen-reader-text:focus,
.screen-reader-shortcut:focus,
.widget_search input[type="submit"]:focus {
	background: var(--white);
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #333;
	display: block;
	font-size: 1em;
	font-weight: 700;
	height: auto;
	padding: 15px 23px 14px;
	text-decoration: none;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}

.more-link {
	display: inline-block;
	position: relative;
	margin-bottom: 30px;
}
