/* Typographical Elements
--------------------------------------------- */

html {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

body {
	--background-color: var(--white);
	--foreground-color: var(--dark);
	--link-color: var(--primary);
	--link-inner-color: var(--primary-contrast);
	--link-border-color: var(--link-color);
	--link-hover-color: var(--secondary);
	--link-hover-inner-color: var(--secondary-contrast);
	--link-hover-border-color: var(--link-hover-color);
	
	background-color: var(--background-color);
	color: var(--foreground-color);
	font-family: var(--body-font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	line-height: var(--line-height);
	margin: 0;
	overflow-x: hidden;
}

button,
input:focus,
input[type="button"],
input[type="reset"],
input[type="submit"],
textarea:focus,
.button,
.gallery img {
	transition: all 0.2s ease-in-out;
}

a {
	color: var(--link-color);
	text-decoration: underline;
	transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, text-shadow 0.2s ease-in-out;
}

a:focus,
a:hover {
	color: var(--link-hover-color);
	text-decoration: none;
}

/* additions... */

.overlay-coloured a,
.background-coloured a {
	color: var(--white);
}

.overlay-coloured a:hover,
.overlay-coloured a:focus,
.background-coloured a:hover,
.background-coloured a:focus {
	color: var(--white);
}

/* ...additions */

p {
	margin: 0 0 var(--paragraph-margin-v);
	padding: 0;
}

ol,
ul {
	margin: 0;
	padding: 0;
}

li {
	list-style-type: none;
}

hr {
	border: 0;
	border-collapse: collapse;
	border-bottom: 1px solid currentColor;
	clear: both;
	color: #eee;
	margin: 1.65em auto;
}

b,
strong {
	font-weight: 700;
}

blockquote,
cite,
em,
i {
	font-style: italic;
}

mark {
	background: #ddd;
	color: #333;
}

blockquote {
	margin: 30px;
}

/* additions... */

blockquote::before {
	content: "\201C";
	display: block;
	font-size: 30px;
	height: 0;
	left: -20px;
	position: relative;
	top: -10px;
}

a, a:active, 
a:focus, 
:focus {
	border: none;
	outline: none;
}

.caption-text {
	font-size: 14px;
	font-style: italic;
}

/* ...additions */
