/* Structure and Layout
---------------------------------------------------------------------------- */

/* Site Container
--------------------------------------------- */

.site-container {
	animation: fadein 1s;
	word-wrap: break-word;
	border-top: 0 solid var(--secondary);
}

@keyframes fadein {

	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}

}

.site-container > * {
	background-color: var(--background-color);
	color: var(--foreground-color);
}

/* Site Inner
--------------------------------------------- */

.site-inner {
	clear: both;
	margin: 0 auto;
	padding: 60px 30px 0;
}

/* additions */

/* .site-inner {	
	max-width: 100%;
	width: 100%;
	margin: 0 auto;
}

.page-template-page_archive .site-inner {
	max-width: 1240px;
	padding: 50px 20px;
}

.page-template-default .site-inner {
	max-width: 1240px;
	padding: 260px 20px 50px;
}

.home .site-inner {
	padding: 0;
}

.single-post .site-inner,
.archive .site-inner, 
.blog .site-inner, 
.error404 .site-inner {
	max-width: 1240px;
    width: 100%;
    margin: 250px auto 40px;
    padding: 0 20px;
	float: none;
} */
