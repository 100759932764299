/* Pagination
--------------------------------------------- */

.pagination {
	clear: both;
	margin: 60px 0;
}

.adjacent-entry-pagination {
	margin-bottom: 0;
}

.archive-pagination li {
	display: inline;
}

.archive-pagination li a {
	background-color: #f5f5f5;
	color: #333;
	cursor: pointer;
	display: inline-block;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 4px;
	padding: 8px 12px;
	text-decoration: none;
}

.archive-pagination li a:focus,
.archive-pagination li a:hover,
.archive-pagination li.active a {
	background-color: #333;
	color: var(--white);
}
