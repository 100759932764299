/* ACF general styling */

.heading-block-content .reg-text ul {
	margin-left: 40px;
	margin-bottom: 20px;
}

.heading-block-content .reg-text li {
	list-style-type: disc;
	margin-left: 20px;
}

.border-box {
	border: 1px solid var(--dark);
	padding: 40px;
}

.heading-block-container,
.full-section-container {
	background-color: var(--background-color);
	color: var(--foreground-color);
}

.cols-1 {
	width: 100%;
	float: left;
}

.cols-2 {
	width: 50%;
	float: left;
}

.cols-3 {
	width: 33.33%;
	float: left;
}

.cols-4 {
	width: 25%;
	float: left;
}

.full-section-container {
	/* float: left; */
	width: 100%;
	position: relative;
}

.page .site-inner .full-section-container {
	float: none;
	width: 100vw;
	position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.full-width-imageblock-container img {
	display: block;
	width: 100%;
}

.fixed-section-wrap {
	max-width: 860px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px;
	overflow: hidden;
}

.wide-fixed-section-wrap {
	max-width: 1240px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px;
	overflow: hidden;
}

.full-section-wrap {
	width: 100%;
	/* float: left; */
}

.image-fixed-section-wrap {
	max-width: 820px;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
}

.has-object-fit > img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

/* ie and edge fallback for object-fit*/
.compat-object-fit {
  background-size: cover;
  background-position: center center;
}

.compat-object-fit img {
	opacity: 0;
}

.no-padding {
	padding: 0;
}

.space-block {
	width: 100%;
	/* float: left; */
}

.space-block-20 {
	padding-bottom: 20px
}

.space-block-30 {
	padding-bottom: 30px
}

.space-block-40 {
	padding-bottom: 40px
}

.space-block-50 {
	padding-bottom: 50px
}

.space-block-60 {
	padding-bottom: 60px
}

.space-block-80 {
	padding-bottom: 80px
}

.space-block-100 {
	padding-bottom: 100px
}

.equal-height-flex-col {
	flex-grow: 1;
	flex-basis: 0;
}

.anchor-tag {
	/* float: left; */
	width: 100%;
}

/* General Content Blocks */

/* Home page */


/* Standard Page */

.page-template-page_standard .content {
	/*padding: 60px 0;*/
}

.page-template-page_standard {
}

