/* Entry Content
--------------------------------------------- */

.entry {
	margin-bottom: 40px;
}

.entry-content ol,
.entry-content ul {
	margin-bottom: 30px;
	padding-left: 40px;
}

.entry-content ol > li {
	list-style-type: decimal;
}

.entry-content ul > li {
	list-style-type: disc;
}

.entry-content ol ul > li,
.entry-content ul ul > li {
	list-style-type: circle;
}

.entry-content ol ol,
.entry-content ul ul {
	margin-bottom: 0;
}

.entry-content code {
	background-color: #f5f5f5;
}

.entry-content .caption {
	/* additions... */
	font-size: 14px;
	font-weight: 600;
	margin-top: -20px;
	text-align: center;
}

/* .entry-content > .full-section-container + .full-section-container,
.entry-content > .image_text_grid + .image_text_grid {
	margin-top: 0;
}

.entry-content > .full-section-container + *,
.entry-content > * + .full-section-container,
.entry-content > .full-section-container.image_text_grid + *,
.entry-content > * + .full-section-container.image_text_grid,
.entry-content > .image_text_grid + .full-section-container,
.entry-content > .full-section-container + .image_text_grid {
	margin-top: var(--section-gap);
} */

.entry-content > p:first-of-type {
	/* margin-top: var(--section-gap); */
}

.single .entry-content > p:first-child {
	font-size: 112.5%;
	font-family: var(--serif);
}

.entry-content > p:last-of-type {
	/* margin-bottom: var(--section-gap); */
}

.content .sticky {
	background-color: #f5f5f5;
	padding: 30px;
}

.image-text-block-container {
	margin-top: var(--section-gap);
	margin-bottom: var(--section-gap);
}

.entry {
	--section-gap: 60px;
	--paragraph-margin-v: 40px;
	
	margin-bottom: var(--section-gap);
	
	.blog &,
	.archive & {
		--paragraph-margin-v: 20px;
	}
}

.singular-image {
	width: 100%;
	object-fit: cover;
	max-height: 62vh;
}

.archive .content > p {
	display: none;
}

.recent_posts_title {
	margin-bottom: var(--section-gap);
}

.entry-footer .post-permalink a {
	font-size: 0.75em !important;
}

.singular-image {
	margin-bottom: var(--paragraph-margin-v);
}

/* .entry-footer,
.entry-meta {
	.blog &,
	.archive & {
		display: none;
	}
} */

.blog .entry,
.archive .entry {
	border: thin solid #ddd;
	border-left: 2px solid var(--primary);
	padding: calc(var(--paragraph-margin-v) * 2) calc(var(--paragraph-margin-v) * 1.5) var(--paragraph-margin-v);
	/* border-radius: 5px; */
	transition: 0.3s box-shadow;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	
	/* &:hover {
		box-shadow: 0 5px 20px -5px #ddd;
		border-color: var(--primary-semi-transparent);
		border-left-color: var(--primary);
	} */
	
	& > :last-child {
		margin-bottom: 0;
	}
}

