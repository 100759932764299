/* Headings
--------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
	--line-height: 1.2;
	--font-weight: 400;
	--paragraph-margin-v: 20px;
	
	font-family: var(--heading-font-family);
	font-weight: var(--font-weight);
	line-height: var(--line-height);
	margin: 0 0 var(--paragraph-margin-v);
}

h1,
.single .entry-title  {
	font-size: 3em;
}

h2,
.archive .entry-title,
div.wpforms-container-full .wpforms-form .wpforms-title {
	font-size: 2.25em;
}

.entry-content > h2 {
	margin-top: var(--section-gap);
}

.acf-block h2 {
	margin-top: var(--paragraph-margin-v);
}

h3 {
	font-size: 1.5em;
}

h4 {
	font-size: 1.25em;
}

.entry-content h3,
.entry-content h4 {
	--font-weight: 600;
}

.entry-content h4 {
	margin-top: calc(var(--paragraph-margin-v) * 2);
}

h5 {
	font-size: 1.125em;
}

h6 {
	font-size: 1em;
}
