/* FlexSlider Default Theme
*********************************/
.flexslider {
	float: left;
    width: 100%;
	position: relative; 
	zoom: 1;
}

.flexslider .slides {
	zoom: 1;
	margin: 0 auto;
}

ul.slides {
	padding-left: 0;
}

.flexslider .slides > li {
	position: relative;
	list-style-type: none;
}

.slides .has-object-fit {
	max-height: 62vh;
	min-height: 100%;
}

/* Suggested container for "Slide" animation setups. Can replace this with your own, if you wish */
.flex-container {
	zoom: 1; 
	position: relative;
}

/* Caption style */
/* IE rgba() hack */
.flex-caption {
	background:none; 
	-ms-filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#4C000000,endColorstr=#4C000000);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#4C000000,endColorstr=#4C000000); 
	zoom: 1;
}

.flex-caption {
	background: rgba(0,0,0,.3);
	bottom: 0; 
	color: var(--white); 	 
	font-size: 14px;
	left: 0; 
	line-height: 18px;
	padding: 2%; 
	position: absolute;
	text-shadow: 0 -1px 0 rgba(0,0,0,.3);
	width: 96%;
}

/* Direction Nav */

.flex-direction-nav {
	display: none;
}

/*.flex-direction-nav li a {
	width: 52px; 
	height: 52px; 
	margin: -20px 0 0; 
	display: block; 
	background: url(images/bg_direction_nav.png) no-repeat 0 0; 
	position: absolute; 
	top: 50%; 
	cursor: pointer; 
	text-indent: -9999px; 
	z-index: 100;
}

.flex-direction-nav li .next {
	background-position: -52px 0; 
	right: -60px;
}

.flex-direction-nav li .next:hover {
	background-position: -52px -50px; 
}

.flex-direction-nav li .prev {
	left: -60px;
}

.flex-direction-nav li .prev:hover {
	background-position: 0 -50px;
}

.flex-direction-nav li .disabled { 	 
	cursor: default;
	filter:alpha(opacity=30);
	opacity: .3;
}*/

/* Control Nav */
.flex-control-nav {
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 100;
    bottom: 30px;
    line-height: 1;
}

.flex-control-nav li {
	display: inline;
	display: inline-block;
	margin: 0 0 0 7px;
	zoom: 1;
}

.flex-control-nav li:first-child {
	margin: 0;
}

.flex-control-nav li a {	 
	width: 5px;
    height: 5px;
    display: block;
    background: rgba(245,243,236,1);
    cursor: pointer;
    text-indent: -9999px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.flex-control-nav li a:hover {
	background: rgba(245,243,236,0.5);
}

.flex-control-paging li a.flex-active {
	background: rgba(245,243,236,0.5);
	cursor: default;
}

/* Header slider */

.home-slider-container-filler {
	/* padding: 78px; */
}

#slider-1 {
	& .slides li {
		background-size: cover;
		background-position: center;
		height: calc(100vh - 226px);
	}
}

.video-slide {
	position: relative;
}

.slider-overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 4;
	display: none;
}

svg#video-overlay {
	shape-rendering: optimizeSpeed;
	stroke: var(--secondary-semi-transparent)
}

.slider-background-video.is-playing.is-visible + .slider-overlay {
	/* display: block; */
}
