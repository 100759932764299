/* Image Grid Row */

.image-grid-row-container {
	background-color: var(--dark);
}

.image-grid-row-wrapper {
	display: flex;
}

.image-with-content-image-container {
	height: 100%;
}

.image-grid-item-container {
	width: 25%;
	padding: 2px;
}

/* .site-container h2 {
	margin-top: 1em;
} */

.image-text-item-wrap {
	position: relative;

}
.image-text-item-wrap::after {
	content: "";
	position: absolute;
	top: 20px;
	left: 20px;
	bottom: 20px;
	right: 20px;
	border: thin solid #ddd;
	z-index: 2;
}

/* Image and text block */

.image-text-block-container {
	padding: 0;
}

.image-text-block-container a {

}

.image-text-block-container a:hover,
.image-text-block-container a:focus {
	/* color: var(--dark); */
}

.image-text-item-container + .image-text-item-container {
	padding-top: var(--section-gap);
}

.image-text-item-wrap {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
}

.image-text-block-image {
	width: 55%;
}

.image-text-block-verbose .image-text-block-image {
	width: calc(45% - 80px);
}

.flexslider.slider-2 .slides {
	display: flex;
	height: 100%;
}

.image-text-content-container {
	width: 45%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 3;
}

.image-text-block-verbose .image-text-content-container {
	width: 55%;
}

.image-text-content-container h2 {
	font-style: normal;
}

.image-text-block-container .image-text-content-container {
	padding: 60px;
}

.image-text-block-container:nth-child(odd) .image-text-content-container {
	padding-left: 80px;
	padding-right: 40px;
}

.image-text-block-container:nth-child(even) .image-text-content-container {
	padding-right: 80px;
	padding-left: 40px;

}

.image-text-block-container:nth-child(even) .image-text-block-image {
	order: 1;
}

.image-text-block-container:nth-child(even) .image-text-content-container {
	text-align: right;
}

.image-text-block-container:nth-child(even) .image-text-content-container {
	align-items: flex-end;
}

/* Image and text Grid */

.image-text-grid-container {
	padding: 0;
}

.image-text-grid-item-container {
	display: flex;
	flex-wrap: wrap;
}

.image-text-grid-image {
	width: 50%;
}

.image-text-grid-content-container {
	width: 50%;
	display: flex;
}

.image-text-block-container:nth-child(odd) .image-text-grid-content-container {
	justify-content: flex-start;
}

.image-text-block-container:nth-child(even) .image-text-grid-content-container {
	justify-content: flex-end;
}

.image-text-grid-content-wrap {
	width: 600px;
	display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-text-block-container:nth-child(odd) .image-text-grid-content-wrap {
	padding: 80px 20px 80px 120px;
}

.image-text-block-container:nth-child(even) .image-text-grid-content-wrap {
	padding: 80px 120px 80px 20px;
}

.image-text-grid-content-wrap h2 {
	font-style: normal;
}

.align-centered .image-text-grid-content-wrap .image-text-grid-text,
.align-centered .image-text-grid-content-wrap h2 {
    width: 100%;
	text-align: center;
}

.image-text-block-container:nth-child(even) .image-text-grid-image {
	order: 1;
}

.image-text-block-container:nth-child(even) .image-text-grid-content-wrap {
	float: right;
	text-align: right;
}

.image-text-block-container:nth-child(even) .image-text-grid-content-wrap {
	align-items: flex-end;
}

.image-text-block-button-container {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}

.image-text-block-container:nth-child(odd) .image-text-block-button-container {
    justify-content: flex-start;
}


.image-text-block-container:nth-child(even) .image-text-block-button-container {
    justify-content: flex-end;
}

.align-centered .image-text-block-button-container {
	width: 100%;
    align-items: center;
    justify-content: center;
}

.image-text-block-button-container .button-wrap {
	padding: 10px 0;
}

.align-centered .image-text-block-button-container .button-wrap.button-no-1 {
	margin-right: 0;
	margin-left: 0;
}

.image-text-block-button-container .button-wrap.button-no-2 {
	--link-color: var(--primary-contrast);
	--link-inner-color: var(--primary);
	--link-hover-inner-color: var(--link-color);
	--link-border-color: var(--link-inner-color);
	--link-hover-border-color: var(--link-hover-color);

	margin-left: 20px;
}

.image-text-block-text ul {
	padding-left: 0;
}

.image-text-block-text ul:last-child {
	margin-bottom: 0;
}

.image-text-block-text ul > li {
	list-style: none;
}

.image-text-block-text ul > li + li {
	margin-top: 0.417em;
}


.image-text-block-container:nth-child(odd) .image-text-block-image {
	margin-right: 0;
}

.image-text-block-container:nth-child(even) .image-text-block-image {
	margin-left: 0;
}

.entry-content a {
	font-weight: 400;
}



/* wysiwyg in banner */
/* smaller icons in small stuck header nav */
/* image block full width */
