/* Tables
--------------------------------------------- */

table {
	border-collapse: collapse;
	border-spacing: 0;
	margin-bottom: 40px;
	width: 100%;
	word-break: break-all;
}

tbody {
	border-bottom: 1px solid #eee;
}

td,
th {
	line-height: 2;
	text-align: left;
	vertical-align: top;
}

td {
	padding: 0.5em;
}

tr {
	border-top: 1px solid #eee;
}

th {
	font-weight: 600;
	padding: 0.5em;
}
