/* Common Classes
---------------------------------------------------------------------------- */

/* Avatar
--------------------------------------------- */

.avatar {
	border-radius: 50%;
	float: left;
}

.author-box .avatar,
.alignleft .avatar {
	margin-right: 20px;
}

.alignright .avatar {
	margin-left: 20px;
}

.comment .avatar {
	margin: 0 15px 20px 0;
}

/* Genesis
--------------------------------------------- */

.after-entry,
.archive-description,
.author-box {
	margin-bottom: 40px;
}

.after-entry {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
	padding: 20px 30px;
}

.after-entry .widget:last-of-type {
	margin-bottom: 0;
}

.breadcrumb {
	/* border-bottom: 1px solid #eee; */
	font-size: 0.875em;
	margin-bottom: calc(var(--section-gap) / 2);
	/* padding-bottom: 10px; */
}

.genesis-title-hidden .breadcrumb {
	margin-top: 40px;
}

.archive-description p:last-child,
.author-box p:last-child {
	margin-bottom: 0;
}

/* Search Form
--------------------------------------------- */

.search-form {
	overflow: hidden;
}

.entry-content .search-form {
	margin-bottom: 40px;
	width: 50%;
}

.post-password-form input[type="submit"],
.search-form input[type="submit"] {
	margin-top: 10px;
}

.widget_search input[type="submit"] {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	padding: 0;
	position: absolute;
	width: 1px;
}

/* Titles
--------------------------------------------- */

.archive-description .entry-title,
.archive-title,
.author-box-title {
	margin-bottom: 10px;
}

/* .entry-title {
	font-size: 30px;
	margin-bottom: 10px;
} */

.entry-title a,
.sidebar .widget-title a {
	color: var(--dark);
	text-decoration: none;
}

.entry-title a:focus,
.entry-title a:hover {
	color: var(--link-hover-color);
	text-decoration: underline;
}

.widget-title {
	font-size: 1.125em;
	margin-bottom: 20px;
}

.genesis-title-hidden .site-inner {
	padding-top: 0;
}

/* WordPress
--------------------------------------------- */

a.aligncenter img {
	display: block;
	margin: 0 auto;
}

a.alignnone {
	display: inline-block;
}

.alignleft {
	float: left;
	text-align: left;
}

.alignright {
	float: right;
	text-align: right;
}

a.alignleft,
a.alignnone,
a.alignright {
	max-width: 100%;
}

img.centered,
.aligncenter,
.singular-image {
	display: block;
	margin: 0 auto var(--paragraph-margin-v);
;
}

img.alignnone,
.alignnone {
	margin-bottom: 15px;
}

a.alignleft,
img.alignleft,
.wp-caption.alignleft {
	margin: 0 20px 20px 0;
}

a.alignright,
img.alignright,
.wp-caption.alignright {
	margin: 0 0 20px 20px;
}

figcaption,
.gallery-caption,
.wp-caption-text {
	font-size: 0.875em;
	font-weight: 500;
	margin-top: 0.5em;
	margin-bottom: 1em;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
}

.entry-content p.wp-caption-text {
	margin-bottom: 0;
}

.entry-content .wp-audio-shortcode,
.entry-content .wp-playlist,
.entry-content .wp-video {
	margin: 0 0 30px;
}


.wp-block-image {
	margin-bottom: var(--paragraph-margin-v);
}

.read-more {
		display: inline;
	/* margin-top: calc(0px - var(--paragraph-margin-v)); */
}

.read-more-link {
	/* width: 100%; */
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
}
