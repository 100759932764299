/* Site Header
---------------------------------------------------------------------------- */

.site-header {
	--background-color: var(--secondary);
	--foreground-color: var(--secondary-contrast);
	--link-color: var(--foreground-color);
	--link-hover-color: var(--primary);
	
	background-color: var(--background-color);
	color: var(--foreground-color);
	box-shadow: 0 5px 15px var(--dark-subtle-transparent);
	/* additions */
	transition: all .4s ease-in-out;
	z-index: 10;
	right: 0;
	left: 0;
	top: 0;
	z-index: 6;
	position: sticky;
	.admin-bar & {
		top: 32px;
	}
}

#wp-toolbar,
#wp-toolbar #wp-admin-bar-top-secondary {
	background-color: inherit;
}

#wp-admin-bar-top-secondary {
	opacity: 0.7;
}

#wp-admin-bar-top-secondary:hover {
	opacity: 1;
}
	/* additions... */


.site-header > .wrap {
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 1240px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px;
}

/* ...additions */

/* Title Area
--------------------------------------------- */

.title-area {
	float: left;
	padding-bottom: 25px;
	padding-top: 25px;
	/* additions */
	max-width: 300px;
	padding: 20px 0 10px;
}

.wp-custom-logo .title-area {
	max-width: 310px;
	width: 100%;
	height: 100%;
	padding-top: 10px !important;
	padding-bottom: 5px;
	margin-bottom: -15px;
}

.wp-custom-logo .stickHeader .title-area {
	padding-top: 10px !important;
	padding-bottom: 5px;
	margin-bottom: 0;
}

.wp-custom-logo .custom-logo-link {
	display: block;
	height: 100%;
}

.wp-custom-logo .title-area img {
	width: auto;
	height: 100%;
}

.site-title {
	--link-color: var(--primary);
	font-family: var(--heading-font-family);
	font-size: 40px;
	font-weight: 600;
	line-height: 1;
	margin-bottom: 0;
}

.site-title a,
.site-title a:focus,
.site-title a:hover {
	text-decoration: none;
}

.site-description,
.wp-custom-logo .site-title {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

/* .custom-logo-link {
	transition: background-color 0.2s;
	background-color: white;
}

.custom-logo-link img {
  mix-blend-mode: multiply;
}

.custom-logo-link:hover {
	background-color: var(--primary);
} */

/* additions... header widget area */

.header-widget-area {
	position: absolute;
	right: 0;
	top: 20px;
}

.header-widget-area .widget {
	margin-bottom: 0;
}

/* Sticky Header */

/* .site-container.stickHeader {
	padding-top: 156px;
} */

.site-header.stickHeader {
	--background-color: var(--secondary-slightly-transparent);
	backdrop-filter: blur(5px);
	position: sticky;
	box-shadow: 0px 5px 15px -5px var(--dark-semi-transparent);
}

.site-header.stickHeader > .wrap {
	flex-direction: row;
	align-items: stretch;
	height: 100%;
}

.stickHeader .title-area {
	/* additions */
	max-width: 160px;
	min-width: 120px;
	padding: 10px 0;
	padding-top: 10px !important;
}

.stickHeader .site-title {
	font-size: 20px;
}

.stickHeader .header-widget-area {
	position: relative;
	display: flex;
	align-items: center;
	top: 0;
	order: 1;
}

/* .stickHeader ~ .booking-button-container {
	top: 100px;
} */

.stickHeader .ulitily-bar {
	padding: 0;
}

.site-header.stickHeader .genesis-nav-menu a {
	font-size: 15px;
}

.stickHeader .ulitily-bar-wrap {
	margin-bottom: 0;
	margin-top: 0;
}
.stickHeader .cart-contents {
	width: 20px;
}

.stickHeader .cart-text {
	display: none;
}
.stickHeader .cart-count {
	display: none;
}

.stickHeader .social-header-social-container {
	padding: 0;
}

.social-icon-item {
	transition: 0.5s transform;
}

.social-icon-item a {
	transition: 0.5s opacity;
}

.stickHeader .social-icon-item {
	transform: scale(0.75);
}

img.alt-custom-logo {
	display: none;
}

.stickHeader img.alt-custom-logo {
	display: block;
}

.stickHeader img.custom-logo {
	display: none;
}
