/* Widgets
---------------------------------------------------------------------------- */

.widget {
	margin-bottom: 40px;
}

.widget p:last-child,
.widget ul > li:last-of-type {
	margin-bottom: 0;
}

.widget ul > li {
	margin-bottom: 10px;
}

.widget ul > li:last-of-type {
	padding-bottom: 0;
}

.widget ol > li {
	list-style-position: inside;
	list-style-type: decimal;
	padding-left: 20px;
	text-indent: -20px;
}

.widget li li {
	border: 0;
	margin: 0 0 0 30px;
	padding: 0;
}

.widget_calendar table {
	width: 100%;
}

.widget_calendar td,
.widget_calendar th {
	text-align: center;
}

/* Featured Content
--------------------------------------------- */

.featured-content .entry {
	border-bottom: 1px solid #eee;
	margin-bottom: 20px;
}

.featured-content .entry:last-of-type {
	border-bottom: none;
	margin-bottom: 0;
}

.featured-content .entry-title {
	font-size: 16px;
	margin-bottom: 5px;
	margin-top: 10px;
}

.featured-content .entry-image { /* additions */
	height: 75px;
	width: 75px;
}
