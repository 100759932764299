/* booking buttons */

.booking-button-container {
	--link-color: var(--secondary-semi-transparent);
	--foreground-color: var(--white);
	--link-hover-color: var(--foreground-color);
	--foreground-hover-color: var(--secondary);
	
	position: absolute;
	top: 32px;
	left: 0;
	right: 0;
	max-width: 1240px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px;
	z-index: 5;
}

.booking-button-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}

.booking-button-container .button-wrap {
	padding: 3px 0;
}

.booking-button-container .button-wrap a {
	--text-shadow-color: var(--dark);
	--background-color: var(--link-alt-color);
	/* border: 2px solid; */
	width: 100%;
	color: var(--foreground-color);
	background-color: var(--link-color);
	border-radius: 5px;
	text-shadow: 0 0 5px var(--text-shadow-color);
}

.booking-button-container .button-wrap a:hover {
	--text-shadow-color: transparent;
	--background-color: var(--link-hover-color);
	--foreground-color: var(--foreground-hover-color);
		background-color: var(--background-color);
		border-color: var(--background-color);
}
