/* Footer Widgets
---------------------------------------------------------------------------- */

.footer-widgets {
  --background-color: var(--secondary);
  --foreground-color: var(--secondary-contrast);

  border-top: 1px solid #eee;
  clear: both;
  padding: 60px 0;
  background-color: var(--background-color);
  color: var(--foreground-color);
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 14px;
  padding: 40px 0 40px;
}

.footer-widgets .wrap {
  margin-left: auto;
  margin-right: auto;
}

.footer-widget-area {
  margin-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
}

.footer-widget-area:last-child,
.footer-widgets .widget:last-child {
  margin-bottom: 0;
}

/* ADDITIONS... */

.footer-widgets .wrap {
  max-width: 1240px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
}

.footer-widgets-1 {
  width: 100%;
}

.footer-widgets-2 {
  width: 40%;
}

.footer-widgets-3 {
  width: 60%;
}

.footer-widgets .widget {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.footer-widgets .widget .widget-wrap {
  width: 100%;
}

.footer-widgets .wrap {
  margin-left: auto;
  margin-right: auto;
}

.footer-widgets .widget-area {
  padding-left: 0;
  padding-right: 0;
}

.footer-widgets #menu-footer-menu {
  display: flex;
  flex-wrap: wrap;
}

.footer-widgets #menu-footer-menu .menu-item {
  width: 33.33%;
  margin-bottom: 5px;
}

.footer-widgets h3 {
  color: var(--white);
  font-family: var(--body-font-family);
  font-size: 24px;
  margin-bottom: 10px;
}

.footer-widgets p,
.footer-widgets a {
  color: var(--white);
  font-family: var(--body-font-family);
  font-size: 17px;
  letter-spacing: 0.05em;
  font-weight: 400;
  text-decoration: none;
}

/* Footer Social Block */

.footer-social-block-container {
  padding-bottom: 40px;
  /* float: left; */
  width: 100%;
}

.social-heading-block-container {
  float: right;
}

.social-heading-block-container h2 {
  font-family: var(--body-font-family);
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

.social-heading-icons-container {
  display: flex;
  float: right;
}

.social-heading-contact-container,
.social-heading-social-container {
  display: flex;
}

.social-heading-icons-container > * {
  margin-left: 30px;
}

.social-icon-item {
  width: 35px;
  margin-left: 10px;
}

.social-icon-item a {
  opacity: 0.5;
}

.social-icon-item a:hover {
  opacity: 1;
}

.social-heading-title-block {
  width: 100%;
  margin-bottom: 10px;
}

h2.social-block-heading {
  margin: 0 0 5px;
  font-style: normal;
}

.footer-form {
  /* float: left; */
  width: 100%;
}

.footer-form .wpforms-container-full {
  margin-top: 1rem;
  margin-bottom: 0;
}

.footer-form .wpforms-container .wpforms-form .wpforms-field input {
  padding: 12.5px 15px;
}

.wpforms-one-liner .wpforms-field-container,
.wpforms-one-liner .wpforms-form {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-end;
}

/* .wpforms-one-liner .wpforms-field, */
.wpforms-one-liner .wpforms-field-row,
.wpforms-one-liner .wpforms-field-two-up,
/* .wpforms-one-liner .wpforms-field-row-block, */
.wpforms-one-liner .wpforms-field-container,
.wpforms-one-liner .wpforms-submit-container {
  display: inline-flex;
  display: contents;
}

.wpforms-one-liner .wpforms-field,
.wpforms-one-liner .wpforms-submit-container {
  padding: 0 !important;
}

.wpforms-one-liner .wpforms-field-row,
.wpforms-one-liner .wpforms-field,
.wpforms-one-liner .wpforms-field-small,
.wpforms-one-liner .wpforms-field-medium,
.wpforms-one-liner .wpforms-field-large {
  max-width: none !important;
}

.wpforms-one-liner .wpforms-field-row-block,
.wpforms-one-liner .wpforms-field {
  flex-basis: 200px;
  flex-grow: 1;
}

.wpforms-one-liner .wpforms-field-row-block,
.wpforms-one-liner .wpforms-field:not(.wpforms-field-two-up),
.wpforms-one-liner button {
  margin-top: 10px !important;
  margin-left: 10px !important;
}

.wpforms-one-liner .wpforms-form {
  margin-top: -10px;
  margin-left: -10px;
}

.wpforms-one-liner .wpforms-field,
.wpforms-one-liner
  .wpforms-field-two-up
  .wpforms-field-row-block:not(.wpforms-first),
.wpforms-one-liner .wpforms-submit-container,
div.wpforms-container-full.wpforms-one-liner .wpforms-form .wpforms-first {
  margin-left: 10px !important;
}

.wpforms-one-liner .wpforms-field-two-up .wpforms-field:first-of-type {
  margin-left: 0 !important;
}

.wpforms-one-liner .wpforms-field-row-block {
  width: auto !important;
}

/* Site Footer
---------------------------------------------------------------------------- */

.site-footer {
  background-color: var(--white);
  border-top: 1px solid #eee;
  font-size: 15px;
  line-height: 1.5;
  padding: 30px;
  text-align: center;
}

.site-footer .wrap > p {
  margin-bottom: 0;
  font-family: var(--heading-font-family);
}

/* ...additions */

.site-footer {
  color: var(--dark);
  /* font-size: 1.5rem; */
  /* text-transform: uppercase; */
  padding: 15px 0;
  text-align: left;
  /* float: left; */
  width: 100%;
}

.site-footer .wrap {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.nav-secondary .genesis-nav-menu a {
  text-transform: initial;
  font-size: inherit;
  letter-spacing: 0;
  text-decoration: underline;
}

.site-footer .nav-secondary {
  font-family: var(--heading-font-family);
  margin: 10px -10px 0;
  & .wrap {
    padding-left: 0;
    padding-right: 0;
  }
}

.site-footer a {
  color: inherit;
}

.site-footer p,
.site-footer nav {
  text-transform: inherit;
}

.footer-credit {
  font-size: 10px;
  font-weight: 400;
  text-transform: none;
  width: 100%;
  text-align: right;
  margin-top: 20px;
}

.footer-social-block-container .wide-fixed-section-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.social-block-content-wrapper {
  width: 100%;
}
.social-heading-title-block,
.social-heading-block-container {
  display: contents;
}
.social-block-heading {
  order: 1;
  flex-grow: 2;
  flex-shrink: 2;
  width: auto;
  display: inline-block;
}
.social-heading-icons-container {
  order: 2;
  width: max-content;
  max-width: 50vw;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-end;
}

.inst-shortcode-wrap .envira-gallery-wrap {
  margin: 0;

  & .envira-album-public,
  &.envira-gallery-public {
    margin: 0;
  }
}
