/* Entry Comments
--------------------------------------------- */

.comment-respond,
.entry-comments,
.entry-pings {
	margin-bottom: 40px;
}

.comment-list li {
	padding: 40px 0 0 30px;
}

.comment-list .depth-1 {
	padding-left: 0;
}

.comment-header {
	margin-bottom: 30px;
}

.comment-content {
	clear: both;
}

.comment-content ul > li {
	list-style-type: disc;
}

.comment-respond input[type="email"],
.comment-respond input[type="text"],
.comment-respond input[type="url"] {
	width: 50%;
}

.comment-respond label {
	display: block;
	margin-right: 12px;
}

.comment-header p {
	margin-bottom: 0;
}

.entry-pings .reply {
	display: none;
}

.comment-form-cookies-consent label {
	display: inline;
	padding-left: 10px;
}
