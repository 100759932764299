/* Forms
--------------------------------------------- */

input,
select,
textarea {
	background-color: var(--white);
	border: 1px solid #ddd;
	color: #333;
	font-size: 18px;
	font-weight: 400;
	padding: 15px;
	width: 100%;
}

input:focus,
textarea:focus {
	border: 1px solid #999;
	outline: none;
}

input[type="checkbox"],
input[type="image"],
input[type="radio"] {
	width: auto;
}

:-ms-input-placeholder {
	color: #333;
	opacity: 1;
}

::placeholder {
	color: #333;
	opacity: 1;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"],
.site-container div.wpforms-container-full .wpforms-form input[type="submit"],
.site-container div.wpforms-container-full .wpforms-form button[type="submit"],
.button,
.white-button {
	background-color: var(--link-color);
	border: thin solid var(--link-border-color);
	border-radius: var(--border-radius);
	color: var(--link-inner-color);
	cursor: pointer;
	font-weight: 600;
	padding: 0.833em 1.333em;
	min-height: 44px;
	line-height: 1.125;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	text-decoration: none;
	white-space: normal;
	width: auto;
}

button:focus,
button:hover,
input[type="button"]:focus,
input[type="button"]:hover,
input[type="reset"]:focus,
input[type="reset"]:hover,
input[type="submit"]:focus,
input[type="submit"]:hover,
.site-container div.wpforms-container-full .wpforms-form input[type="submit"]:focus,
.site-container div.wpforms-container-full .wpforms-form input[type="submit"]:hover,
.site-container div.wpforms-container-full .wpforms-form button[type="submit"]:focus,
.site-container div.wpforms-container-full .wpforms-form button[type="submit"]:hover,
.button:focus,
.button:hover {
	background-color: var(--link-hover-color);
	color: var(--white);
	border: thin solid var(--link-border-color);
}

.entry-content .button:focus,
.entry-content .button:hover {
	color: var(--link-hover-inner-color);
	border-color: var(--link-hover-border-color);
}

/* additions... */

a.button.small,
button.small,
input[type="button"].small,
input[type="reset"].small,
input[type="submit"].small {
	padding: 0.5em 1em;
}

/* ...additions */

.site-container button:disabled,
.site-container button:disabled:hover,
.site-container input:disabled,
.site-container input:disabled:hover,
.site-container input[type="button"]:disabled,
.site-container input[type="button"]:disabled:hover,
.site-container input[type="reset"]:disabled,
.site-container input[type="reset"]:disabled:hover,
.site-container input[type="submit"]:disabled,
.site-container input[type="submit"]:disabled:hover {
	background-color: #eee;
	border-width: 0;
	color: #777;
	cursor: not-allowed;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button {
	display: none;
}

/* additions... */

a.white-button {
	/* --link-color: var(--white); */
	position: relative;
	background-color: transparent;
	border: thin solid;
  padding: 0.625em 2.1875em;
}


a.white-button:focus,
a.white-button:hover {
	background-color: var(--foreground-color);
	color: var(--background-color);
}

.shortcode-button-container {
	float: left;
	min-width: 250px;
}

.footer-form .wpforms-form {
	font-size: 1rem;
	
	button {
		padding-top: 13.5px;
		padding-bottom: 13.5px;
		line-height: 1.3;
	}
}

div.wpforms-container-full .wpforms-form .wpforms-first {
	clear: both !important;
	margin-left: 0 !important;
}

div.wpforms-container-full .wpforms-form input.wpforms-field-medium, div.wpforms-container-full .wpforms-form select.wpforms-field-medium, div.wpforms-container-full .wpforms-form .wpforms-field-row.wpforms-field-medium {
	max-width: none;
}

.site-inner div.wpforms-container-full {
  max-width: 680px;
  padding: 30px;
  border: thin solid var(--primary);
}

.wpforms-title {
	font-family: var(--serif);
}
