:root {
	--white: #fff;
  --dark: #231f20;
	/* --primary: #56854E; */
	--primary: #80A944;
	--secondary: #53665D;
	
	/* --primary-semi-transparent: #56854E77; */
	--primary-semi-transparent: #80A94477;
	--secondary-slightly-transparent: #53665DEE;
	--secondary-semi-transparent: #53665D77;
	--white-semi-transparent: #fffd;
	--dark-slightly-transparent: #231f20EE;
	--dark-semi-transparent: #231f2077;
	--dark-subtle-transparent: #231f2011;
	
	--primary-contrast: var(--white);
	--secondary-contrast: var(--white);

  --gilda: "Gilda Display", "Palatino Linotype", "Book Antiqua", "Palatino", serif;
	/* --futura: 'Didact Gothic', Tahoma, Geneva, sans-serif; */
	--futura: 'Jost', 'Futura', Tahoma, Geneva, sans-serif;
	
	--serif: var(--gilda);
	--sans-serif: var(--futura);
	
	--body-font-family: var(--sans-serif);
	--heading-font-family: var(--serif);
	
	--uppercase-letter-spacing: 0.05em;
	--uppercase-text-transform: uppercase;
	--uppercase-font-size: 90%;
	
	--section-gap: 80px;
	
	--font-size: 16px;
	--line-height: 1.625;
	--font-weight: 300;
	--font-size-960: 1.25em;
	
	/* --heading-font-miltipluer: var(--serif); */
		
	--paragraph-margin-v: 28px;
	
	--border-radius: 5px;
}
